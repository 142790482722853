import * as React from 'react'
import AboutHeader from '../components/about_header'
import Container from '../components/container'
import DemoForm from '../components/demo_form'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Link from '../components/link'

const ContactPage = () => (
  <Layout>
    <Seo
      title='Contact Us'
      description='We want to hear from you. Contact Roadster by email or phone.'
    />
    <AboutHeader title='Contact Us' />
    <div className='Section Section--stripe'>
      <Container size='lg'>
        <div className='md:flex'>
          <div className='md:size-1of2 text-center uppercase'>
            <h2 className='uppercase mt-0'>
              Call
            </h2>
            <div className='text-xl sm:text-2xl font-light'>
              1-800-755-7355
            </div>
          </div>
          <div className='md:size-1of2 text-center'>
            <h2 className='uppercase mt-4 md:mt-0'>
              Email
            </h2>
            <div className='sm:text-md pt-1'>
              <div className='mb-1'>
                Dealer Sales:
                {' '}
                <Link
                  href='mailto:dealersales@roadster.com'
                  children='dealersales@roadster.com'
                />
              </div>
              <div className='mb-1'>
                Dealer Support:
                {' '}
                <Link
                  href='mailto:dealersupport@roadster.com'
                  children='dealersupport@roadster.com'
                />
              </div>
              <div className='mb-1'>
                Media Inquiries:
                {' '}
                <Link
                  href='mailto:media@roadster.com'
                  children='media@roadster.com'
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
    <div className='Section Section--dark'>
      <Container>
        <DemoForm invert={true} />
      </Container>
    </div>
  </Layout>
)

export default ContactPage
